import React, {useEffect, useState} from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import ListBrand from '../../components/list-brand';
import ListLink from '../../components/list-link';
import Image from '../../components/Image';

const NewsPage = () => {
  const [pageList, setPageList] = useState('');
  // Ajax設定
  const configJson = {
    method: 'get',
    url: '/update/news/list.json',
    headers: {
      Accept: 'application/json',
    },
    data: '',
  };
  // Ajax実行
  const dataFetch = async () => {
    axios(configJson)
      .then((response) => {
        setPageList(response.data.list);
      })
      .catch(() => {
        // console.log(error);
      });
  };
  // Event
  useEffect(() => {
    dataFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout page="news-page list-page list-page-day">
      <Seo title="NEWS"/>
      <section className="page-head">
        <ListBrand/>
        <ListLink/>
        <h2 className="main-title"><span>NEWS</span></h2>
      </section>
      <section className="page-content full-layout">
        <div className="main-content">
          <div className="content-block">
            <h3 className="sub-title"><span>お知らせ一覧</span></h3>
            <p>アンデルセンストーブからのお知らせをお届けいたします。</p>
          </div>
          <div className="content-block">
            <ul className="news-body">
              {pageList
                ? pageList.reverse().map((list) => (
                  <li key={list.title}>
                    <Link to={`/news/detail/?id=${list.link}`} className="text-link max">
                      <span>{list.day}</span>
                      <div className="flex items-center">{list.new ? <p className="new mr-4">NEW</p> : ''}<p>{list.title}</p></div>
                    </Link>
                  </li>
                ))
                : null}
            </ul>
          </div>
        </div>
      </section>
      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>お知らせ一覧</li>
        </ul>
      </section>
    </Layout>
  );
};

export default NewsPage;
